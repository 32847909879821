<template>
  <div class="container">
    <!-- 顶部 -->
    <div class="head">
      <van-uploader preview-size="100" :after-read="justAfterRead">
        <img class="headImg" v-if="userInfo.HeadImg" :src="userInfo.HeadImg" />
        <img class="headImg" v-else src="@/assets/newImg/direct_i_heard.png" />
      </van-uploader>
      <div class="headTxt">
        <div class="headTxtName">
          {{ userInfo.NickName }}
          <span>{{ userInfo.GradeType }}
            {{ $t('common').Star.toUpperCase() }}</span>
        </div>
        <div class="headTxtId">ID：{{ userInfo.Id }}</div>
      </div>
      <div class="header_right" @click="$router.push('/Language')">
        <p class="txt">{{ langTxt }}</p>
        <van-icon class="rotate90" name="play" size="14" color="#fff" />
      </div>
    </div>
    <!-- 统计 -->
    <div class="statistics" @click="$router.push({ name: 'Audit' })">
      <div class="st_1">
        <div class="st_num">{{ userInfo.AllOrderNum }}</div>
        <div class="st_msg">{{ $t('user').AllOrders }}</div>
      </div>
      <div class="st_2">
        <div class="st_num">{{ userInfo.WaitOrderNum }}</div>
        <div class="st_msg">{{ $t('pay').Wait }}</div>
      </div>
      <div class="st_3">
        <div class="st_num">{{ userInfo.SuccessOrderNum }}</div>
        <div class="st_msg">{{ $t('pay').Audited }}</div>
      </div>
    </div>
    <!-- 上级 -->
    <div class="referrer">
      <div class="re_txt">
        <span @click="onCopy(userInfo.InvitationId)">
          {{ $t('common').ReferrerID }}：{{ userInfo.InvitationId }}</span>
        <span @click="onCopy(userInfo.InvitationNickName)">
          {{ $t('common').NickName }}：{{ userInfo.InvitationNickName }}</span>
      </div>
      <div class="re_txt">
        <span v-if="userInfo.InvitationPhoneNumber" @click="onCopy(userInfo.InvitationPhoneNumber)">
          {{ $t('common').Phone }}：{{ userInfo.InvitationPhoneNumber }}</span><span v-if="userInfo.InvitationEmail"
          @click="onCopy(userInfo.InvitationEmail)">{{ $t('common').Email }}：{{ userInfo.InvitationEmail }}</span>
      </div>
    </div>
    <!-- 功能 -->
    <div class="feature">
      <div class="fe_item" @click="$router.push({ name: 'Payment' })">
        <div class="fe_i_l">
          <img src="@/assets/newImg/my_b_payment.png" width="17px" />
          <span> {{ $t('user').PaymentMethod }}</span>
        </div>
        <img src="@/assets/newImg/my_b_right.png" width="7px" />
      </div>
      <div class="fe_line"></div>
      <div class="fe_item" @click="$router.push({ name: 'EditPassword' })">
        <div class="fe_i_l">
          <img src="@/assets/newImg/my_b_changepwd.png" width="17px" />
          <span>{{ $t('user').ChangePassword }}</span>
        </div>
        <img src="@/assets/newImg/my_b_right.png" width="7px" />
      </div>
      <div class="fe_line"></div>
      <div class="fe_item" @click="toShare">
        <div class="fe_i_l">
          <img src="@/assets/newImg/my_b_sponsored.png" width="17px" />
          <span>{{ $t('user').ShareLink }}</span>
        </div>
        <img src="@/assets/newImg/my_b_right.png" width="7px" />
      </div>
      <div class="fe_line"></div>
      <div class="fe_item" @click="$router.push({ name: 'ApplyRecord' })">
        <div class="fe_i_l">
          <img src="@/assets/newImg/my_b_record.png" width="17px" />
          <span>{{ $t('user').ApplicationRecord }}</span>
        </div>
        <img src="@/assets/newImg/my_b_right.png" width="7px" />
      </div>
      <div class="fe_line"></div>
      <div class="fe_item" @click="goService">
        <div class="fe_i_l">
          <img src="@/assets/newImg/my_b_help.png" width="17px" />
          <span>{{ $t('user').ContactCustomer }}</span>
        </div>
        <img src="@/assets/newImg/my_b_right.png" width="7px" />
      </div>
      <div class="fe_line"></div>
      <div class="fe_item" @click="showLogOut = true">
        <div class="fe_i_l">
          <img src="@/assets/newImg/my_b_signout.png" width="17px" />
          <span>{{ $t('user').SignOut }}</span>
        </div>
        <img src="@/assets/newImg/my_b_right.png" width="7px" />
      </div>
    </div>
    <!-- 退出登录弹窗 -->
    <van-dialog v-model="showLogOut" :showConfirmButton="false">
      <div class="dialog_upLock">
        <div class="di_info">
          <div class="di_i_title">{{ $t('user').WhetherOut }}？</div>
          <div class="di_i_btn">
            <div class="di_i_b_left" @click="Logout">
              {{ $t('user').Quit.toUpperCase() }}
            </div>
            <div class="di_i_b_right" @click="showLogOut = false">
              {{ $t('common').cancel.toUpperCase() }}
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
    <TabBar :active="'User'" />
  </div>
</template>

<script>
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import TabBar from '@/components/tabBarNew'

export default {
  components: {
    TabBar
  },
  data() {
    return {
      changeNameTxt: '',
      showLogOut: false,
      userInfo: {},
      PrivacyList: [],
      privacyId: null,
      lang_list: [
        { text: 'English', lang: 'en', selected: false },
        { text: '中文', lang: 'zh', selected: false }
        // { text: 'ภาษาไทย', lang: 'ty', selected: false }, //泰语
        // { text: '日本語です', lang: 'jp', selected: false }, //日语
        // { text: '한국인', lang: 'hy', selected: false }, //韩语
        // { text: 'Indonesia', lang: 'yn', selected: false }, //印尼语
        // { text: 'Việt nam', lang: 'yuenan', selected: false }, //越南文
        // { text: 'កម្ពុជា', lang: 'gmw', selected: false }, //高棉文
        // { text: 'Français', lang: 'fy', selected: false }, //法语
        // { text: 'Español', lang: 'xby', selected: false }, //西班牙语
        // { text: 'Русский язык', lang: 'ey', selected: false }, //俄语
        // { text: 'Português', lang: 'pty', selected: false }, //葡萄牙语
        // { text: 'Deutsch', lang: 'dy', selected: false } //德语
      ],
      langTxt: 'English'
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  watch: {},
  mounted() {
    this.lang_int()
    this.getAccountBase()
  },
  methods: {
    //语言初始化
    lang_int() {
      this.lang_list.forEach((item, index, arr) => {
        if (item.lang == localStorage.getItem('language'))
          this.langTxt = item.text
      })
    },
    //联系客服
    async goService() {
      const res = await userApi.getClientConfig()
      this.$router.push({
        name: 'IframePage',
        query: { IframePage: res.Data.ServiceUrl }
      })
      //  location.href = res.Data.ServiceUrl
    },
    toShare() {
      if (this.userInfo.GradeType < 5) this.$toast(this.$t('user').ShareLimtMsg)
      else this.$router.push({ name: 'Share' })
    },
    //上传图片回调
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.userInfo.HeadImg = await userApi.getCaptchaFile(formdata)
      this.editHeadImg()
    },
    //修改头像
    async editHeadImg() {
      const form = {
        NickName: this.userInfo.NickName,
        HeadImg: this.userInfo.HeadImg
      }
      await userApi.editHeadImg(form)
      this.getAccountBase()
      this.$toast(this.$t('common').success)
    },
    //获取用户信息
    async getAccountBase() {
      const aBase = await userApi.getAccountBase()
      this.userInfo = aBase.Data
      localStorage.setItem('userInfo', JSON.stringify(aBase.Data))
    },
    //复制方法
    onCopy(val) {
      navigator.clipboard.writeText(val).then(() => {
        this.$toast(this.$t('common').CopySuccess)
      })
    },
    //退出登录
    Logout() {
      Token.remove()
      localStorage.removeItem('userInfo')
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #f0f2f5;
  padding-bottom: 60px;
}

.head {
  height: 161px;
  background-image: url(../../assets/newImg/donate_p_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0px 20px 30px 20px;
  display: flex;
  align-items: center;

  .headImg {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    // box-shadow: 0 2px 5px 0 rgba(226, 227, 233, 0.75);
  }

  .headTxt {
    margin-left: 15px;

    .headTxtName {
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 15px;
      color: #ffffff;
      display: flex;

      span {
        width: 39px;
        height: 17px;
        background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
        border-radius: 5px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 8px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
      }
    }

    .headTxtId {
      margin-top: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .header_right {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 20px;
    margin-bottom: 35px;

    .txt {
      margin: 0 10px;
    }

    .img {
      margin-left: 3px;
      margin-bottom: 2px;
    }

    .rotate90 {
      transform: rotate(90deg);
    }
  }
}

.statistics {
  margin-top: -50px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .st_num {
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 7px;
  }

  .st_msg {
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 13px;
    color: #ffffff;
  }

  .st_1 {
    width: 112px;
    height: 63px;
    background: linear-gradient(0deg, #fd8993 0%, #e1565f 100%);
    box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
    border-radius: 10px;
    text-align: center;
    padding: 13px;
  }

  .st_2 {
    width: 112px;
    height: 63px;
    background: linear-gradient(0deg, #307ef4 0%, #59a3f8 100%);
    box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
    border-radius: 10px;
    text-align: center;
    padding: 13px;
  }

  .st_3 {
    width: 112px;
    height: 63px;
    background: linear-gradient(0deg, #f6b95c 0%, #f3a125 100%);
    box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
    border-radius: 10px;
    text-align: center;
    padding: 13px;
  }
}

.referrer {
  margin: 5px 10px;
  padding: 10px 15px;
  height: 63px;
  background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
  box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
  border-radius: 10px;

  .re_txt {
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 9px;
    color: #ffffff;
  }
}

.feature {
  margin: 15px 10px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
  border-radius: 10px;

  .fe_item {
    padding: 15px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fe_i_l {
      display: flex;
      justify-content: left;
      align-items: center;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 17px;
      color: #333333;

      span {
        margin-left: 15px;
      }
    }
  }

  .fe_line {
    margin: 0 10px 0 35px;
    height: 1px;
    background: #dedede;
  }
}

.scroll {
  background: #ffffff;
  margin-top: 10px;
}

.list {
  .item {
    display: flex;
    height: 50px;
    padding: 0 20px;
    align-items: center;
    font-size: 16px;
    color: #515151;

    .right {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #f5f5f5;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
}

.serve {
  color: rgb(0, 122, 255);
  display: flex;
  align-items: center;
  padding: 5px 15px 20px;

  img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
}

.btn {
  margin: 20px auto;
  background: #ffd140;
  font-size: 16px;
  font-weight: 500;
  color: #141416;
  width: 345px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .van-dialog {
  background-color: transparent;
  width: 320px;
}

.dialog_upLock {
  background-image: url(../../assets/newImg/dialog_warn.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .di_info {
    width: 100%;
    height: 280px;
    padding-top: 135px;
    text-align: center;

    .di_i_title {
      padding: 0 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 24px;
      color: #ff6600;
      line-height: 30px;
    }

    .di_i_btn {
      margin: 60px 15px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .di_i_b_left {
        width: 135px;
        height: 39px;
        background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
        border-radius: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .di_i_b_right {
        width: 135px;
        height: 39px;
        border-radius: 20px;
        border: 1px solid #ff9103;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
        color: #ff9103;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
